import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { initializeApp } from 'firebase/app';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCMN-uIR9mKkt44oa_mlkcd3Js8k6KQ8Eo",
  authDomain: "blueprotocol-72e6a.firebaseapp.com",
  databaseURL: "https://blueprotocol-72e6a.firebaseio.com",
  projectId: "blueprotocol-72e6a",
  storageBucket: "blueprotocol-72e6a.appspot.com",
  messagingSenderId: "1072446313211",
  appId: "1:1072446313211:web:3035c6f6d4a92827d3165e",
  measurementId: "G-0J9J0JDKGB"
};

  firebase.initializeApp(firebaseConfig);

  const projectFirestore = firebase.firestore();
  const projectAuth = firebase.auth();
  const projectStorage = firebase.storage();
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const timestamp = firebase.firestore.FieldValue.serverTimestamp;

  export { projectFirestore, projectAuth, projectStorage, db, timestamp }

