import { ref } from 'vue';
import { db, projectFirestore } from '../firebase/config';
import { collection, getDocs, orderBy } from 'firebase/firestore';

const getPosts = () => {
    const posts = ref([]);
    const error = ref(null);

    const load = async () => {
      try {
        
        // await projectFirestore.collection('posts').get()
        //   .then(snap => {
        //     snap.docs.forEach(doc => {
        //       return { ...doc.data(), id: doc.id }
        //     })
        //   })
        const postsCol = collection(db, 'posts');
        const postsSnap = await getDocs(postsCol, orderBy('createdAt'));
        posts.value = postsSnap.docs.map(doc => {
            return { ...doc.data(), id: doc.id }
        });

        console.log(posts.value)
        // const docRef = doc(db, "posts").get();
        // console.log(docRef);

        // await res.get().then(function(snapshot) {
        //     console.log(snapshot);
        // });
        // console.log(res.docs);

        // await new Promise(resolve => {
        //     setTimeout(resolve, 2000);
        // })

        // let data = await fetch('http://localhost:3000/posts');
        // if (!data.ok){
        //   throw Error('no data available');
        // }
        // posts.value = await data.json();
      }
      catch (err) {
        error.value = err.message;
        console.log(error.value);
      }
    }

    return { posts, error, load}
}

export default getPosts;