<template>
  <div class="home">
    <PostsList :posts="posts"/>
  </div>
</template>

<script>
// @ is an alias to /src
import PostsList from '@/components/posts/PostsList.vue';
import getPosts from '../composables/getPosts';
import Spinner from '../components/shared/Spinner.vue';

export default {
  name: 'HomeView',
  components: {
    PostsList, Spinner,
  },
  setup() {
    const { posts, error, load } = getPosts();
    load();
    return { posts, error }
  }
}
</script>

<style>
.home {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}
.layout {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 20px;
}
</style>