<template>
  <form @submit.prevent="handleSubmit">
    <h3>Signup</h3>
    <input type="text" placeholder="Display Name" v-model="displayName">
    <input type="email" placeholder="Email" v-model="email">
    <input type="password" placeholder="Password" v-model="password">
    <div v-if="error" class="error">{{ error }}</div>
    <button v-if="!isPending">Create Login</button>
    <button v-if="isPending" disabled>Loading</button>
  </form>
</template>

<script>
import useSignup from '../../composables/useSignup';
import { ref } from 'vue';

export default {
    setup() {
        const {error, signup, isPending } = useSignup();
        const email = ref('');
        const password = ref('');
        const displayName = ref('');

        const handleSubmit = async () => {
            const res = await signup(email.value, password.value, displayName.value);
            if (!error.value){
                console.log('user created');
            }
        }

        return { email, password, handleSubmit, error, isPending }
    }
}
</script>

<style>

</style>