<template>
    <div class="post">
        <router-link :to="{ name: 'home' }">
            <h3>{{ post.title }}</h3>
            <img :src="post.headerUrl" />
            <div class="post-right-info">
                <div class="post-date">
                    <span class="day">{{ postDay }}</span>
                    <span class="month">{{ postMonth }}</span>
                </div>
                <!-- <div class="post-likes">
                    <i class="fa fa-heart text-theme"></i>
                    <span class="number">520</span>
                </div> -->
            </div>
        </router-link>
        <p>{{ snipped }}</p>
        <!-- <span v-for="tag in post.tags" :key="tag">
            <router-link>#{{ tag }}</router-link>
        </span> -->
    </div>
</template>

<script>
import { computed, ref } from '@vue/runtime-core'

export default {
    props: ['post'],
    setup(props) {
        const snipped = computed(() => {
            return props.post.body.substring(0, 100) + " ...";
        });

        let postDate = props.post.createdAt.toDate();
        const postDay = postDate.getDay();
        const postMonth = postDate.getMonth();
        // const postDay = ref('Test');
        // const postMonth = ref('JAN');
        
        return { snipped, postDay, postMonth }
    }
}
</script>

<style>
.post {
    margin: 0 40px 30px;
    padding-bottom: 30px;
    border-bottom: 1px dashed #e7e7e7;
}
.post img {
    margin: 0 40px 30px;
    display: inline-block;
    width: 60%;
    height: auto;
}
.post-right-info {
    display: flex;
}

.post-date {
    display: inline-block;
    position: relative;
    font-size: 26px;
    color: white;
    margin-bottom: 10px;
    max-width: 400px;
}

.post-likes {
    color: blue;
}
</style>