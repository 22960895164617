<template>
    <!-- begin post-list -->
    <div class="post-list">
        <div v-for="post in posts" :key="post.id">
            <Post :post="post" />
        </div>
    </div>
    <!-- end post-list -->
</template>

<script>
import Post from '../../components/posts/Post.vue';
export default {
    props: ['posts'],
    components: { Post },
    setup(props) {
        
    }
}
</script>

<style>

</style>