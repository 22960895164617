import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue';
import Login from '../views/auth/Login.vue';
import Signup from '../views/auth/Signup.vue';
import PostDetail from '../views/posts/PostDetail.vue';
import { projectAuth } from '@/firebase/config';
import News from '../views/News.vue';
import Guides from '../views/Guides.vue';
import Builds from '../views/Builds.vue';
import Map from '../views/Map.vue';
import Wiki from '../views/Wiki.vue';

// auth guard
const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  if (!user) {
    next({ name: 'home'});
  }
  else {
    if (user.email != 'brian.montfort@gmail.com') {
      next({ name: 'home'});
    }
    else
    {
      next();
    }
  }
}

const requireNoAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  if (user) {
    next({ name: 'Chatroom'});
  }
  else {
    next();
  }
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/news',
    name: 'News',
    component: News
  },
  {
    path: '/builds',
    name: 'Builds',
    component: Builds
  },
  {
    path: '/guilds',
    name: 'Guides',
    component: Guides
  },
  {
    path: '/map',
    name: 'Map',
    component: Map
  },
  {
    path: '/wiki',
    name: 'Wiki',
    component: Wiki
  },
  {
    path: '/posts/:id',
    name: 'PostDetail',
    component: PostDetail
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/admin/AdminDashboard.vue'),
    beforeEnter: requireAuth
  },
  {
    path: '/admin/posts/create',
    name: 'CreatePost',
    component: () => import('../views/admin/managePosts/CreatePost.vue'),
    beforeEnter: requireAuth
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
